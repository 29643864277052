// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-add-tsx": () => import("./../../../src/pages/add.tsx" /* webpackChunkName: "component---src-pages-add-tsx" */),
  "component---src-pages-baby-tsx": () => import("./../../../src/pages/baby.tsx" /* webpackChunkName: "component---src-pages-baby-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-drugs-tsx": () => import("./../../../src/pages/drugs.tsx" /* webpackChunkName: "component---src-pages-drugs-tsx" */),
  "component---src-pages-exam-tsx": () => import("./../../../src/pages/exam.tsx" /* webpackChunkName: "component---src-pages-exam-tsx" */),
  "component---src-pages-html-tsx": () => import("./../../../src/pages/html.tsx" /* webpackChunkName: "component---src-pages-html-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-local-tsx": () => import("./../../../src/pages/local.tsx" /* webpackChunkName: "component---src-pages-local-tsx" */),
  "component---src-pages-m-tsx": () => import("./../../../src/pages/m.tsx" /* webpackChunkName: "component---src-pages-m-tsx" */),
  "component---src-pages-mcq-2017-1-tsx": () => import("./../../../src/pages/mcq-2017-1.tsx" /* webpackChunkName: "component---src-pages-mcq-2017-1-tsx" */),
  "component---src-pages-mcq-2017-2-tsx": () => import("./../../../src/pages/mcq-2017-2.tsx" /* webpackChunkName: "component---src-pages-mcq-2017-2-tsx" */),
  "component---src-pages-mcq-2018-1-tsx": () => import("./../../../src/pages/mcq-2018-1.tsx" /* webpackChunkName: "component---src-pages-mcq-2018-1-tsx" */),
  "component---src-pages-mcq-2018-2-tsx": () => import("./../../../src/pages/mcq-2018-2.tsx" /* webpackChunkName: "component---src-pages-mcq-2018-2-tsx" */),
  "component---src-pages-mcq-2019-1-tsx": () => import("./../../../src/pages/mcq-2019-1.tsx" /* webpackChunkName: "component---src-pages-mcq-2019-1-tsx" */),
  "component---src-pages-mcq-2019-2-tsx": () => import("./../../../src/pages/mcq-2019-2.tsx" /* webpackChunkName: "component---src-pages-mcq-2019-2-tsx" */),
  "component---src-pages-mcq-2020-1-tsx": () => import("./../../../src/pages/mcq-2020-1.tsx" /* webpackChunkName: "component---src-pages-mcq-2020-1-tsx" */),
  "component---src-pages-mcq-2020-2-tsx": () => import("./../../../src/pages/mcq-2020-2.tsx" /* webpackChunkName: "component---src-pages-mcq-2020-2-tsx" */),
  "component---src-pages-mcq-2021-1-tsx": () => import("./../../../src/pages/mcq-2021-1.tsx" /* webpackChunkName: "component---src-pages-mcq-2021-1-tsx" */),
  "component---src-pages-mcq-2021-2-tsx": () => import("./../../../src/pages/mcq-2021-2.tsx" /* webpackChunkName: "component---src-pages-mcq-2021-2-tsx" */),
  "component---src-pages-mcq-2022-1-tsx": () => import("./../../../src/pages/mcq-2022-1.tsx" /* webpackChunkName: "component---src-pages-mcq-2022-1-tsx" */),
  "component---src-pages-mcq-2022-r-tsx": () => import("./../../../src/pages/mcq-2022-R.tsx" /* webpackChunkName: "component---src-pages-mcq-2022-r-tsx" */),
  "component---src-pages-notes-tsx": () => import("./../../../src/pages/notes.tsx" /* webpackChunkName: "component---src-pages-notes-tsx" */),
  "component---src-pages-saq-2017-1-tsx": () => import("./../../../src/pages/saq-2017-1.tsx" /* webpackChunkName: "component---src-pages-saq-2017-1-tsx" */),
  "component---src-pages-saq-2017-2-tsx": () => import("./../../../src/pages/saq-2017-2.tsx" /* webpackChunkName: "component---src-pages-saq-2017-2-tsx" */),
  "component---src-pages-saq-2018-1-tsx": () => import("./../../../src/pages/saq-2018-1.tsx" /* webpackChunkName: "component---src-pages-saq-2018-1-tsx" */),
  "component---src-pages-saq-2018-2-tsx": () => import("./../../../src/pages/saq-2018-2.tsx" /* webpackChunkName: "component---src-pages-saq-2018-2-tsx" */),
  "component---src-pages-saq-2019-1-tsx": () => import("./../../../src/pages/saq-2019-1.tsx" /* webpackChunkName: "component---src-pages-saq-2019-1-tsx" */),
  "component---src-pages-saq-2019-2-tsx": () => import("./../../../src/pages/saq-2019-2.tsx" /* webpackChunkName: "component---src-pages-saq-2019-2-tsx" */),
  "component---src-pages-saq-2020-1-tsx": () => import("./../../../src/pages/saq-2020-1.tsx" /* webpackChunkName: "component---src-pages-saq-2020-1-tsx" */),
  "component---src-pages-saq-2020-2-tsx": () => import("./../../../src/pages/saq-2020-2.tsx" /* webpackChunkName: "component---src-pages-saq-2020-2-tsx" */),
  "component---src-pages-saq-2021-1-tsx": () => import("./../../../src/pages/saq-2021-1.tsx" /* webpackChunkName: "component---src-pages-saq-2021-1-tsx" */),
  "component---src-pages-saq-2021-2-tsx": () => import("./../../../src/pages/saq-2021-2.tsx" /* webpackChunkName: "component---src-pages-saq-2021-2-tsx" */),
  "component---src-pages-saq-2022-1-tsx": () => import("./../../../src/pages/saq-2022-1.tsx" /* webpackChunkName: "component---src-pages-saq-2022-1-tsx" */),
  "component---src-pages-saq-all-tsx": () => import("./../../../src/pages/saq-all.tsx" /* webpackChunkName: "component---src-pages-saq-all-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-stuff-tsx": () => import("./../../../src/pages/stuff.tsx" /* webpackChunkName: "component---src-pages-stuff-tsx" */),
  "component---src-pages-summaries-tsx": () => import("./../../../src/pages/summaries.tsx" /* webpackChunkName: "component---src-pages-summaries-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-third-tsx": () => import("./../../../src/pages/third.tsx" /* webpackChunkName: "component---src-pages-third-tsx" */),
  "component---src-pages-verbs-tsx": () => import("./../../../src/pages/verbs.tsx" /* webpackChunkName: "component---src-pages-verbs-tsx" */),
  "component---src-pages-viva-2021-2-tsx": () => import("./../../../src/pages/viva-2021-2.tsx" /* webpackChunkName: "component---src-pages-viva-2021-2-tsx" */),
  "component---src-templates-directory-tsx": () => import("./../../../src/templates/directory.tsx" /* webpackChunkName: "component---src-templates-directory-tsx" */),
  "component---src-templates-html-tsx": () => import("./../../../src/templates/html.tsx" /* webpackChunkName: "component---src-templates-html-tsx" */),
  "component---src-templates-image-tsx": () => import("./../../../src/templates/image.tsx" /* webpackChunkName: "component---src-templates-image-tsx" */),
  "component---src-templates-markdown-tsx": () => import("./../../../src/templates/markdown.tsx" /* webpackChunkName: "component---src-templates-markdown-tsx" */)
}

